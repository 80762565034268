var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-page"},[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"tab-arr":_vm.tabArr,"on-fetch":_vm.getList,"on-put-back":_vm.handlePutBack},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('list-filter',{attrs:{"filterForm":_vm.filterForm,"uploadFilter":_vm.refreshList}})]},proxy:true},{key:"tabletop",fn:function(){return undefined},proxy:true},{key:"item_name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"goods-name"},[_c('div',{staticClass:"left"},[(row.orderGoods[0])?_c('list-image',{attrs:{"fit":"cover","width":80,"border-radius":"5%","src":row.orderGoods[0].image}}):_vm._e()],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"goods-attr"},[_vm._v(_vm._s(row.orderGoods[0].name))]),_c('div',{staticClass:"goods-specs"},[_vm._v(" "+_vm._s(Object.values(row.orderGoods[0].specs.attrs).join(";"))+" ")]),(row.orderGoods.length > 1 )?_c('div',{staticClass:"more"},[_c('el-popover',{attrs:{"placement":"right-end","trigger":"hover"}},[_c('el-table',{attrs:{"data":row.orderGoods,"max-height":"300px"}},[_c('el-table-column',{attrs:{"min-width":"250","prop":"specs","label":"商品名称/属性"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"goods-name"},[_c('div',{staticClass:"left"},[(row)?_c('list-image',{attrs:{"fit":"cover","width":80,"border-radius":"5%","src":row.image}}):_vm._e()],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"goods-attr"},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"goods-specs"},[_vm._v(" "+_vm._s(Object.values(row.specs.attrs).join(";"))+" ")])])])]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"150","prop":"price","label":"单价数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"price_count"},[(row.price>0)?_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(row.price))]):_vm._e(),(row.price>0&&row.integral>0)?_c('div',{staticClass:"price"},[_vm._v(_vm._s('+'))]):_vm._e(),(row.integral>0)?_c('div',{staticClass:"price"},[_vm._v(_vm._s(row.integral)+"积分")]):_vm._e(),_c('div',{staticClass:"count"},[_vm._v("x "+_vm._s(row.amount))])])]}}],null,true)})],1),_c('el-link',{attrs:{"slot":"reference","type":"primary","underline":false},slot:"reference"},[_vm._v("显示更多")])],1)],1):_vm._e()])])]}},{key:"item_count",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"price_count"},[(row.orderGoods[0].price>0)?_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(row.orderGoods[0].price))]):_vm._e(),(row.orderGoods[0].price>0 && row.orderGoods[0].integral>0)?_c('div',{staticClass:"price"},[_vm._v(_vm._s('+'))]):_vm._e(),(row.orderGoods[0].integral>0)?_c('div',{staticClass:"price"},[_vm._v(_vm._s(row.orderGoods[0].integral)+"积分")]):_vm._e(),_c('div',{staticClass:"count"},[_vm._v("x "+_vm._s(row.orderGoods[0].amount))])])]}},{key:"item_order_price",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"order_price"},[_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(row.order_price.toFixed(2)))]),_c('div',{staticClass:"text"},[_vm._v("运费：￥"+_vm._s(_vm.totalFreight(row.orderGoods)))]),(false)?_c('div',{staticClass:"text"},[_vm._v("改价：-￥0.00")]):_vm._e()])]}},{key:"item_user",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"user"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"left"},[_vm._v("买家：")]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(row.user.nickname))])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"left"},[_vm._v("会内职务：")]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(_vm._f("placeholder")(row.user.title_name)))])]),(row.remarks)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"left"},[_vm._v("备注：")]),_c('div',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm._f("placeholder")(row.remarks))+" "),_c('el-link',{staticStyle:{"margin-left":"5px"},attrs:{"underline":false,"type":"primary"}},[_vm._v("修改")])],1)]):_vm._e()])]}},{key:"item_status",fn:function(ref){
var row = ref.row;
return [_c('list-status-text',{attrs:{"type":row.status == 1
            ? 'warning'
            : row.status == 0
            ? 'success'
            : row.status == 2
            ? 'info'
            : 'danger',"text":row.status_text}})]}},{key:"top",fn:function(){return undefined},proxy:true},{key:"operation",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.editRow(scope.row)}}},[_vm._v("详情")]),(scope.row.status == 1 && false)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.offShelfRow(scope.row.id)}}},[_vm._v("改价")]):_vm._e(),(scope.row.receive_status == 0 && scope.row.status==0 && (scope.row.phone && scope.row.address))?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.deliverRow(scope.row, 'normal')}}},[_vm._v("立即发货")]):_vm._e(),(scope.row.receive_status == 1 && scope.row.status ===0)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.deliverRow(scope.row, 'edit')}}},[_vm._v("修改发货信息")]):_vm._e()]}}])}),_c('DeliverGoodsDialog',{attrs:{"currentItem":_vm.currentItem,"goodsType":_vm.goodsType},on:{"refresh":_vm.refreshList},model:{value:(_vm.openDelvier),callback:function ($$v) {_vm.openDelvier=$$v},expression:"openDelvier"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }