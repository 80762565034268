<template>
  <div class="content-box">
    <el-dialog
      title="订单发货"
      :visible="is_show"
      width="600px"
      class="dialog-vertical"
      @close="close()"
    >
      <div class="receive-Box">
        <div class="user">
          <div class="icon">收</div>
          <div class="info">{{ currentItem.addressee }}</div>
          <div class="phone">{{ currentItem.phone }}</div>
          <div class="address">{{ currentItem.address }}</div>
        </div>
        <div class="goods">
          <el-table :data="currentItem.orderGoods" height="200px">
            <el-table-column min-width="250" prop="specs" label="商品名称/属性">
              <template slot-scope="{ row }">
                <div class="goods-name">
                  <div class="left">
                    <!-- 默认显示第一个商品规格 -->
                    <list-image
                      v-if="row"
                      fit="cover"
                      :width="40"
                      border-radius="5%"
                      :src="row.image"
                    />
                  </div>
                  <div class="right">
                    <div class="goods-attr">
                      {{ row.name }}
                    </div>
                    <div class="goods-specs">
                      {{ Object.values(row.specs.attrs).join(";") }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="150" prop="price" label="单价数量">
              <template slot-scope="{ row }">
                <div class="price_count">
                  <div v-if="row.price" class="price">￥{{ row.price }}</div>
                  <div v-if="row.price >0 && row.integral > 0"> + </div>
                  <div v-if="row.integral" class="price">{{ row.integral }}积分</div>
                  <div class="count">x {{ row.amount }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <el-form
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="85px"
        ref="form"
        @submit.native.prevent="() => {}"
      >
        <el-form-item label="发货方式：">
          <el-radio-group v-model="form.express_info.express_type">
            <el-radio :label="1">自己联系物流</el-radio>
            <el-radio :label="2">无需快递</el-radio>
          </el-radio-group>
          <div class="tips">
            {{
              form.express_info.express_type === 1
                ? "自己联系物流公司下单，上传快递单号完成发货"
                : "该订单中商品属于虚拟商品或者服务，无需物流即可发货"
            }}
          </div>
        </el-form-item>
        <template v-if="form.express_info.express_type === 1">
          <el-form-item label="快递公司：">
            <el-select
              v-model="form.express_info.express_company"
              filterable
              placeholder="请选择或者搜索"
            >
              <el-option
                v-for="(item, i) in companyOptions"
                :key="i"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流单号：">
            <el-input
              placeholder="请输入物流单号"
              v-model="form.express_info.express_num"
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >{{goodsType === 'edit' ? '重新发货' : '发货'}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ListImage from "@/base/components/List/ListImage";
import { saveExpressInfo } from "../../api/order-list";
export default {
  name: "templateDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    currentItem: {
      type: Object,
    },
    goodsType: {
      type: String,
      default: "normal",
    },

  },
  components: { ListImage },
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {
        express_info: {
          express_type: 1, // 发货方式
          express_num: "", // 物流单号
          express_company: "", // 快递公司
        },
      },
      rules: {},
      loading: false,
      // 快递公司选项
      companyOptions: [
        { value: "顺丰速运" },
        { value: "京东快递" },
        { value: "圆通快递" },
        { value: "韵达快递" },
        { value: "中通快递" },
        { value: "极兔快递" },
        { value: "邮政快递" },
        { value: "申通快递" },
        { value: "中通快递" },
        { value: "德邦快递" },
        { value: "邮政包裹" },
      ],
    };
  },
  methods: {
    close() {
      this.is_show = false;
      this.form = {
        express_info: {
          express_type: 1, // 发货方式
          express_num: "", // 物流单号
          express_company: "", // 快递公司
        },
      };
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          if (
            this.form.express_info.express_type === 1 &&
            !this.form.express_info.express_num
          ) {
            this.$message.error("请选择快递公司");
            return;
          }
          if (
            this.form.express_info.express_type === 1 &&
            !this.form.express_info.express_company
          ) {
            this.$message.error("请选择填写物流单号");

            return;
          }
          this.loading = true;

          let postData = {
            orderid: this.currentItem.orderid,
            express_info: [],
          };
          this.currentItem.orderGoods.forEach((item) => {
            postData.express_info.push({
              id: item.id,
              ...this.form.express_info,
            });
          });
          saveExpressInfo(postData)
            .then((res) => {
              this.$message.success('发货成功');
              this.loading = false;
              this.close();
              this.$emit('refresh',true)

            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.goods-name {
  display: flex;

  .left {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .goods-attr {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-specs {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .more {
      el-button {
      }
    }
  }
}

.receive-Box {
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .icon {
      width: 30px;
      height: 30px;
      background-color: coral;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 30px;
      margin-right: 5px;
    }

    .info {
      margin-right: 5px;
    }

    .phone {
      margin-right: 5px;
    }

    .address {
      color: #b3b3b3;
    }
  }
}
.tips {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
